import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertiesType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertiestype/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPropertyType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/propertiestype/leertipo?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropertyType(ctx, typePropertyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/propertiestype/recibetipo', { json: typePropertyData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
