import store from '@/store'

// import { fieldPatternMatcher } from '@casl/ability'
import { ref, watch } from '@vue/composition-api'

export default function usepropertiesTypeList() {
  const propertiesTypeListTable = ref([])

  // Table Handlers
  const propertiesTypeTableColumns = [

    { text: 'Property Type', align: 'start', value: 'propertyType' },
    { text: 'ShortName', value: 'typeShortName' },
    { text: 'Group', value: 'typeGroup' },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['propertyType'],
    sortDesc: [false],
    itemsPerPage: 50,
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const fetchPropertiesType = () => {
    store
      .dispatch('app-invoice/fetchPropertiesType', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response
        const filteredData = data
        propertiesTypeListTable.value = filteredData
        totalInvoiceListTable.value = total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchPropertiesType()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    propertiesTypeTableColumns,
    searchQuery,
    options,
    propertiesTypeListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    fetchPropertiesType,
  }
}
